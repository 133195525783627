import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home";
import Landing from "./landing";
function App() {
  return (
    <BrowserRouter>
      <Routes>
              <Route path="/" element={<Landing />} />
        {/* <Route path="/landing" element={<Home />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

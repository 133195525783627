import React, { useEffect, useState } from "react";
import Home from "./home";

import SliderGif from "./image/slider-gif.gif";
import sliderlogo from "./image/slider-logo.png";
import logo from "./image/landing-page-logo.png";
import MobileLogo from "./image/mobile-logo.png";
import GamePlayImg from "./image/F2P.png";
import GamePlayImg1 from "./image/gameplay-slider-img.png";
import GamePlayImg3 from "./image/F2P2.png";
import GamePlayImg4 from "./image/gameplay-slider-img2.png";
import CopyIcon from "./image/copy-icon.png";
import DiscordIcon from "./image/side-icon-discord.png";
import OpenSeaIcon from "./image/side-icon-opensea.png";
import TwitterIcon from "./image/side-icon-twitter.png";
import NftArt1 from "./image/Nft-art-1.png";
import NftArt2 from "./image/Nft-art-2.png";
import NftArt3 from "./image/Nft-art-3.png";
import NftArt4 from "./image/Nft-art-4.png";
import NftArt5 from "./image/Nft-art-5.png";
import NftArt6 from "./image/Nft-art-6.png";
import NftArt7 from "./image/Nft-art-7.png";
import NftArt8 from "./image/Nft-art-8.png";
import NftArt9 from "./image/Nft-art-9.png";
import NftArt10 from "./image/Nft-art-10.png";
import NftArt11 from "./image/Nft-art-11.png";
import NftArt12 from "./image/Nft-art-12.png";
import NftArt13 from "./image/Nft-art-13.png";
import NftArt14 from "./image/Nft-art-14.png";
import NftArt15 from "./image/Nft-art-15.png";
import NftArt16 from "./image/Nft-art-16.png";
import NftArt17 from "./image/Nft-art-17.png";
import NftArt18 from "./image/Nft-art-18.png";
import NftArt19 from "./image/Nft-art-19.png";
import NftArt20 from "./image/Nft-art-20.png";
import CoreTeam1 from "./image/core-team1.png";
import CoreTeam2 from "./image/core-team2.png";
import CoreTeam3 from "./image/core-team3.png";
import CoreTeam4 from "./image/core-team4.png";
import CoreTeam5 from "./image/core-team5.png";
import CoreTeam6 from "./image/core-team6.png";
import HoverCoreTeam1 from "./image/h-core-team1.png";
import HoverCoreTeam2 from "./image/h-core-team2.png";
import HoverCoreTeam3 from "./image/h-core-team3.png";
import HoverCoreTeam4 from "./image/h-core-team4.png";
import HoverCoreTeam5 from "./image/h-core-team5.png";
import HoverCoreTeam6 from "./image/h-core-team6.png";
import PlayToEarn from "./image/coin-play-img.png";
import PlayToEarnText from "./image/play-2-earn-text.png";
import CoinImg from "./image/coin-image.png";
import Exchange from "./image/exchange.png";
import ExchangeText from "./image/staking.png";
import Trade from "./image/trade.png";
import TradeText from "./image/exchange-text.png";
import Gift from "./image/gift.png";
import GiftText from "./image/ICCBOX.png";
import RoadMapCoin from "./image/roadmap-coin.png";
import PhaseImage from "./image/phase-image.png";
import HoverPhaseImage from "./image/hover-phase-image.png";
import MoonManImg from "./image/moon-man-img.png";
import FooterLogo from "./image/footer-logo.png";
import FooterDiscordIcon from "./image/icon-discord.png";
import FooterOpenSeaIcon from "./image/icon-opensea.png";
import FooterTwitterIcon from "./image/twitter-icon.png";
import FooterCoin from "./image/footer-coin.png";
import MobileMenuIconHome from "./image/home.png";
import MobileMenuIconNFT from "./image/nft.png";
import MobileMenuIconEarn from "./image/earn.png";
import MobileMenuIconTrade from "./image/trade-icon.png";
import MobileMenuIconMore from "./image/more.png";
import MobileSliderIconNext from "./image/button-next-mobile.png";
import MobileSliderIconPrev from "./image/button-previous-mobile.png";
import MobileP2E from "./image/mobile-P2E.png";
import PoolIcon from "./image/pool-icon.png";
import Farmsicon from "./image/farms-icon.png";
import HomeHoverIcon from "./image/home-hover.png";
import NFTHoverIcon from "./image/nft-hover.png";
import EarnHoverIcon from "./image/earn-hover.png";
import TradeHoverIcon from "./image/trade-hover.png";
import MoreHoverIcon from "./image/more-hover.png";
import SubExchangeicon from "./image/dropdown-exchange.png";
import SubLiquidIcon from "./image/liquid-icon.png";
import SubWhitePaperIcon from "./image/white-paper-icon.png";
import SubExchangeiconHover from "./image/sub-trade-hover.png";
import SubLiquidIconHover from "./image/hover-liquid-icon.png";
import SubWhitePaperIconHover from "./image/whitepaper-hover.png";
import HoverPoolIcon from "./image/pool-hover-icon.png";
import HoverFarmsicon from "./image/farmsHover-icon.png";
import MCoreTeam1 from "./image/m-nft-art-1.png";
import MCoreTeam2 from "./image/m-nft-art-2.png";
import MCoreTeam3 from "./image/m-nft-art-3.png";
import MCoreTeam4 from "./image/m-nft-art-4.png";
import MCoreTeam5 from "./image/m-nft-art-5.png";
import MCoreTeam6 from "./image/m-nft-art-6.png";
import HoverMCoreTeam1 from "./image/hm-nft-art-1.png";
import HoverMCoreTeam2 from "./image/hm-nft-art-2.png";
import HoverMCoreTeam3 from "./image/hm-nft-art-3.png";
import HoverMCoreTeam4 from "./image/hm-nft-art-4.png";
import HoverMCoreTeam5 from "./image/hm-nft-art-5.png";
import HoverMCoreTeam6 from "./image/hm-nft-art-6.png";
import NftBorder from "./image/nft-frame.png";
import HoverNftBorder from "./image/nft-frame-hover.png";
function Landing() {
    const [price, setPrice] = useState(0.000007);
    useEffect(() => {
        fetchData();
    }, []);

    // const fetchData = async () => {
    //     // fetch data
    //     const result = await fetch(
    //         "https://api.pancakeswap.info/api/v2/tokens/0x8d672014fb107cb409dccd9042dda3b97313f4c3"
    //     );
    //     const jsonData = await result.json();
    //     if (jsonData) {
    //         setPrice(parseFloat(jsonData.data.price));
    //     }
    // };

    const fetchData = async () => {
        // "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=intergalactic-cockroach&order=market_cap_desc&per_page=100&page=1&sparkline=false"
        const result = await fetch(
            "https://api.coinbrain.com/public/coin-info", {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  "56": ["0x8d672014fb107cb409dccd9042dda3b97313f4c3"]
                 })
              })
      
        const jsonData = await result.json();
        if (jsonData) {
            console.log(jsonData[0]['priceUsd'])
            setPrice(parseFloat(jsonData[0]['priceUsd']));
        }
    };





    const myFunction = (text) => {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(text);
        var tooltip = document.getElementById("myTooltip");
        var tooltip1 = document.getElementById("Tooltip");
        tooltip.innerHTML = "Copied ";
        tooltip1.innerHTML = "Copied ";
    };

    return (
        <div className="landing-bg">
            <div className="landing-page">
                <div className="side-social-icon">
                    <a
                        className="nav-link"
                        href="https://opensea.io/collection/intergalactic-cockroach"
                        rel="noreferrer"
                        alt="side-icon"
                        target="_blank"
                    >
                        <img src={OpenSeaIcon} alt="side-icon" />
                    </a>
                    <a
                        className="nav-link"
                        href="https://twitter.com/icc_vision "
                        rel="noreferrer"
                        alt="side-icon"
                        target="_blank"
                    >
                        <img src={TwitterIcon} alt="side-icon" />
                    </a>
                    <a
                        className="nav-link"
                        href="https://discord.gg/bJrWycyxpa"
                        rel="noreferrer"
                        alt="side-icon"
                        target="_blank"
                    >
                        <img src={DiscordIcon} alt="side-icon" />
                    </a>
                </div>
                <header>
                    <div className="menu-navbar desktop">
                        <div className="row">
                            <div className="col-2 col-xxl-2">
                                <div className="logo">
                                    <img src={logo} alt="logo" className="d-none d-lg-block" />
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="menu-nav d-flex justify-content-end">
                                    <ul className="navbar-nav align-items-center" id="menu">
                                        <li className="nav-item active">
                                            <a
                                                rel="noreferrer"
                                                className="nav-link"
                                                href={"https://icc.vision/"}
                                            >
                                                <span>Home</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                                href={"https://mint.icc.vision/"}
                                            >
                                                <span>nft</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                                href={"https://explore.icc.vision/farms"}
                                            >
                                                <span>Earn</span>
                                            </a>
                                            <ul className="dropdown">
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/farms"}
                                                    >
                                                        <span>farms</span>
                                                    </a>
                                                </li>
                                                <li className="mt-2">
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/pools"}
                                                    >
                                                        <span>pools</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                            >
                                                <span>Trade</span>
                                            </a>
                                            <ul className="dropdown">
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/swap"}
                                                    >
                                                        <span>exchange</span>
                                                    </a>
                                                </li>
                                                <li className="mt-2">
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/liquidity"}
                                                    >
                                                        <span>LIQUIDITY</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                            >
                                                <span>More</span>
                                            </a>
                                            <ul className="dropdown">
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={'https://intergalactic-cockroach.gitbook.io/white-paper/'}
                                                    >
                                                        <span>WHITEPAPER</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="playnow-btn">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={"https://play.icc.vision/"}
                                            >
                                                <span>Play Now</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="menu-navbar mobile d-lg-none">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="menu-nav d-flex justify-content-end">
                                    <ul className="navbar-nav align-items-center" id="menu">
                                        <li className="nav-item active">
                                            <a
                                                rel="noreferrer"
                                                className="nav-link"
                                                href={"https://icc.vision/"}
                                            >
                                                <img
                                                    src={MobileMenuIconHome}
                                                    alt="logo"
                                                    className="Mobile-menu-icon"
                                                />
                                                <img
                                                    src={HomeHoverIcon}
                                                    alt="logo"
                                                    className="hover-icon"
                                                />
                                                <span>Home</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                                href={"https://mint.icc.vision/"}
                                            >
                                                <img
                                                    src={MobileMenuIconNFT}
                                                    alt="logo"
                                                    className="Mobile-menu-icon"
                                                />
                                                <img
                                                    src={NFTHoverIcon}
                                                    alt="logo"
                                                    className="hover-icon"
                                                />
                                                <span>nft</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                            >
                                                <img
                                                    src={MobileMenuIconEarn}
                                                    alt="logo"
                                                    className="Mobile-menu-icon"
                                                />
                                                <img
                                                    src={EarnHoverIcon}
                                                    alt="logo"
                                                    className="hover-icon"
                                                />
                                                <span>Earn</span>
                                            </a>
                                            <ul className="dropdown">
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/pools"}
                                                    >
                                                        <img
                                                            src={PoolIcon}
                                                            alt="logo"
                                                            className="Mobile-menu-icon"
                                                        />
                                                        <img
                                                            src={HoverPoolIcon}
                                                            alt="logo"
                                                            className="pool-icon"
                                                        />
                                                        <span>
                                                            pool
                                                            <p className="d-inline-block text-lowercase m-0">
                                                                s
                                                            </p>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/farms"}
                                                    >
                                                        <img
                                                            src={Farmsicon}
                                                            alt="PoolIcon"
                                                            className="Mobile-menu-icon"
                                                        />
                                                        <img
                                                            src={HoverFarmsicon}
                                                            alt="PoolIcon"
                                                            className="farm-icon"
                                                        />
                                                        <span>
                                                            farm
                                                            <p className="d-inline-block text-lowercase m-0">
                                                                s
                                                            </p>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                            >
                                                <img
                                                    src={MobileMenuIconTrade}
                                                    alt="logo"
                                                    className="Mobile-menu-icon"
                                                />
                                                <img
                                                    src={TradeHoverIcon}
                                                    alt="logo"
                                                    className="hover-icon"
                                                />
                                                <span>Trade</span>
                                            </a>
                                            <ul className="dropdown">
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/swap"}
                                                    >
                                                        <img
                                                            src={SubExchangeicon}
                                                            alt="logo"
                                                            className="Mobile-menu-icon"
                                                        />
                                                        <img
                                                            src={SubExchangeiconHover}
                                                            alt="logo"
                                                            className="trade-icon"
                                                        />
                                                        <span>EXCHANGE</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"
                                                        href={"https://explore.icc.vision/liquidity"}
                                                    >
                                                        <img
                                                            src={SubLiquidIcon}
                                                            alt="PoolIcon"
                                                            className="Mobile-menu-icon"
                                                        />
                                                        <img
                                                            src={SubLiquidIconHover}
                                                            alt="PoolIcon"
                                                            className="liquid-icon"
                                                        />
                                                        <span>LIQUIDITY</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="nav-link"
                                            >
                                                <img
                                                    src={MobileMenuIconMore}
                                                    alt="logo"
                                                    className="Mobile-menu-icon"
                                                />
                                                <img
                                                    src={MoreHoverIcon}
                                                    alt="logo"
                                                    className="hover-icon"
                                                />
                                                <span>More</span>
                                            </a>
                                            <ul className="dropdown">
                                                <li>
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="drodown-item"

                                                    >
                                                        <img
                                                            src={SubWhitePaperIcon}
                                                            alt="logo"
                                                            className="Mobile-menu-icon"
                                                        />
                                                        <img
                                                            src={SubWhitePaperIconHover}
                                                            alt="logo"
                                                            className="white-paper"
                                                        />
                                                        <span>WhitePaper</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section>
                    <div className="container-fluid">
                        <div className="slider py-lg-5 px-xl-4 mx-xl-5">
                            <div className="row justify-content-center">
                                <div className="col-11 col-lg-5">
                                    <div className="slider-text text-center text-lg-start">
                                        <img
                                            src={sliderlogo}
                                            alt="logo"
                                            className=" slider-logo d-none d-lg-block"
                                        />
                                        <img
                                            src={MobileLogo}
                                            alt="logo"
                                            className="slider-logo d-lg-none"
                                        />
                                        <p className="slider-first-text">
                                            Intergalactic Cockroach Space Race is an esport game
                                            based on blockchain.
                                        </p>
                                        <p>
                                            You can gather your friends, race with others and earn
                                            crypto with your skills.
                                        </p>
                                        <h6>#IntergalacticCockroach #ICC #SpaceRace # P2E #NFT</h6>
                                        <h5 className="d-none d-lg-block">$ICC Contract Address</h5>
                                        <div
                                            id="inviteCode"
                                            className="invite-page d-none d-lg-inline-block"
                                        >
                                            <span id="link">
                                                0x8d672014fb107cb409dccd9042dda3b97313f4c3
                                            </span>
                                            <div id="copy" className="tooltip" title="copy">
                                                <button
                                                    onClick={() =>
                                                        myFunction(
                                                            "0x8d672014fb107cb409dccd9042dda3b97313f4c3"
                                                        )
                                                    }
                                                >
                                                    <img src={CopyIcon} alt="copy" />
                                                    <span class="tooltiptext" id="myTooltip">
                                                        Copy
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="playnow-btn d-none d-lg-inline-block">
                                            <a href="https://play.icc.vision/" target="_blank" className="w-100 text-center" id="hover-btn">
                                                <span>Play Now</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-xxl-auto">
                                    <div className="slider-gif">
                                        {/* <img src={SliderGif} alt="slider-img" /> */}
                                        <iframe className="vid-iframe" src="https://www.youtube-nocookie.com/embed/ehbFUobf6sU?rel=0" title="icc intro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                    </div>
                                    <div className="slider-text  d-lg-none">
                                        <div className="px-4">
                                            <h5>$ICC Contract Address</h5>
                                            <div id="inviteCode" className="invite-page">
                                                <span id="mobile_link">
                                                    0x8d672014fb107cb409dccd9042dda3b97313f4c3
                                                </span>
                                                <div id="copy" className="tooltip" title="copy">
                                                    <button
                                                        onClick={() =>
                                                            myFunction(
                                                                "0x8d672014fb107cb409dccd9042dda3b97313f4c3"
                                                            )
                                                        }
                                                    >
                                                        <img src={CopyIcon} alt="copy" />
                                                        <span class="tooltiptext" id="Tooltip">
                                                            Copy
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="playnow-btn d-lg-none">
                                                <a
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={"https://play.icc.vision/"}
                                                    className="w-100"
                                                    id="hover-btn"
                                                >
                                                    <span>Play Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="game-play">
                    <h2 className="section-heading">Gameplay</h2>
                    <p className="subheading-text">
                        Intergalactic Cockroach Space Race's gameplay is designed to
                        challenge your personal skills. Challenge yourself with our modes:
                        Play To Earn & Free Play to win great rewards.
                    </p>
                    <p className="subheading-text"></p>
                    <div className="gameplay-slider">
                        <div
                            id="carouselExampleIndicators"
                            className="carousel slide carousel-fade"
                            data-bs-ride="carousel"
                        >
                            <div className="carousel-indicators">
                                <button
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"
                                ></button>
                                <button
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"
                                ></button>
                            </div>
                            <div className="carousel-inner mt-5">
                                <div className="carousel-item active">
                                    <div className="row align-items-end">
                                        <div className="col-lg-8 col-xxl-6 pb-3 pb-lg-0 text-center">
                                            <img
                                                src={GamePlayImg}
                                                alt="gameplayslider"
                                                className="img-fluid"
                                            />
                                            <div className="d-lg-none">
                                                <h3>FREE PLAY</h3>
                                                <p>
                                                    Win the race and be the last Cockroach standing. Show
                                                    your skills, win 3 rounds and win prizes.
                                                </p>
                                                <div className="playnow-btn d-lg-none">
                                                    <button
                                                        onClick={() =>
                                                            window.open("https://play.icc.vision/", "_blank")
                                                        }
                                                        className="w-100"
                                                    >
                                                        <span>Play Now</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-xxl-6 text-center py-3 d-none d-lg-inline-block">
                                            <img
                                                src={GamePlayImg1}
                                                alt="gameplayslider"
                                                className="img-fluid"
                                            />
                                            <h3>FREE PLAY</h3>
                                            <p>
                                                Win the race and be the last Cockroach standing. Show
                                                your skills, win 3 rounds and win prizes.
                                            </p>
                                            <div className="playnow-btn">
                                                <a
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={"https://play.icc.vision/"}
                                                    className="w-100"
                                                >
                                                    <span>Play Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="row align-items-end">
                                        <div className="col-lg-8 col-xxl-6 pb-3 pb-lg-0 text-center">
                                            <img
                                                src={GamePlayImg3}
                                                className="img-fluid"
                                                alt="gameplayslider"
                                            />
                                            <div className="d-lg-none">
                                                <h3>PLAY 2 EARN</h3>
                                                <p>
                                                    Win the race and be the last Cockroach standing. Show
                                                    your skills, win 3 rounds and win prizes.
                                                </p>
                                                <div className="playnow-btn d-lg-none">
                                                    <a
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        href={"https://play.icc.vision/"}
                                                        className="w-100"
                                                    >
                                                        <span>Play Now</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-xxl-6 text-center py-3 d-none d-lg-inline-block">
                                            <img
                                                src={GamePlayImg4}
                                                className="img-fluid"
                                                alt="gameplayslider"
                                            />
                                            <h3>PLAY 2 EARN</h3>
                                            <p>
                                                Win the race and be the last Cockroach standing. Show
                                                your skills, win 3 rounds and win prizes.
                                            </p>
                                            <div className="playnow-btn">
                                                <a
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={"https://play.icc.vision/"}
                                                    className="w-100"
                                                >
                                                    <span>Play Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gameplay-slider-btn playnow-btn prev">
                                <button
                                    type="button"
                                    className="d-none d-lg-inline-block"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide="prev"
                                >
                                    <span>PREVIOUS</span>
                                </button>
                            </div>
                            <div className="gameplay-slider-btn prev">
                                <button
                                    type="button"
                                    className=" prev-icon d-lg-none"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide="prev"
                                >
                                    <span>
                                        <img
                                            src={MobileSliderIconPrev}
                                            alt="MobileSliderIconPrev"
                                        />
                                    </span>
                                </button>
                            </div>
                            <div className="gameplay-slider-btn playnow-btn next">
                                <button
                                    type="button"
                                    className="d-none d-lg-inline-block"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide="next"
                                >
                                    <span>Next</span>
                                </button>
                            </div>
                            <div className="gameplay-slider-btn next">
                                <button
                                    type="button"
                                    className="next-icon d-lg-none"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide="next"
                                >
                                    <span>
                                        <img
                                            src={MobileSliderIconNext}
                                            alt="MobileSliderIconNext"
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="genesis">
                    <h2 className="section-heading">NFT genesis series</h2>
                    <p className="subheading-text">
                        Play, Stake, Hold or just brag with our NFT Genesis series !
                    </p>
                    <div className="row mint-now justify-content-center mx-0">
                        <div className="col-12 text-center text-xl-end">
                            <div className="playnow-btn">
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={"https://mint.icc.vision/"}
                                >
                                    <span>Mint Now</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="nft-slider gameplay-slider d-none d-lg-block">
                        <div
                            id="nftart"
                            className="carousel slide carousel-fade"
                            data-bs-ride="carousel"
                        >
                            <div className="carousel-indicators">
                                <button
                                    type="button"
                                    data-bs-target="#nftart"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"
                                ></button>
                                <button
                                    type="button"
                                    data-bs-target="#nftart"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"
                                ></button>
                            </div>
                            <div className="carousel-inner mt-5">
                                <div className="carousel-item active">
                                    <div className="row align-items-end justify-content-center">
                                        <div className="col-auto px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt1} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt2} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt3} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt4} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt5} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-lg-0 px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt6} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt7} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt8} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt9} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt10} alt="ntf-border" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="row align-items-end justify-content-center">
                                        <div className="col-auto px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt11} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt12} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt13} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt14} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto mt-2 mt-lg-0 px-0">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt15} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt16} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt17} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt18} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt19} alt="ntf-border" />
                                            </div>
                                        </div>
                                        <div className="col-auto px-0 mt-2">
                                            <div className="nft-border">
                                                <img src={NftBorder} alt="ntf-border" />
                                                <img
                                                    src={HoverNftBorder}
                                                    alt="ntf-border"
                                                    className="HoverNftBorder"
                                                />
                                                <img src={NftArt20} alt="ntf-border" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gameplay-slider-btn playnow-btn prev">
                                <button
                                    type="button"
                                    data-bs-target="#nftart"
                                    data-bs-slide="prev"
                                >
                                    <span>PREVIOUS</span>
                                </button>
                            </div>
                            <div className="gameplay-slider-btn playnow-btn next">
                                <button
                                    type="button"
                                    data-bs-target="#nftart"
                                    data-bs-slide="next"
                                >
                                    <span>Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="nft-slider gameplay-slider d-lg-none">
                        <div className="row align-items-end justify-content-center row-cols-10">
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt1} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt3} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt4} alt="ntf-border" />
                                </div>
                            </div>

                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt10} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt5} alt="ntf-border" />
                                </div>
                            </div>

                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt11} alt="ntf-border" />
                                </div>
                            </div>

                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt12} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt13} alt="ntf-border" />
                                </div>
                            </div>

                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt14} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt15} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt6} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt7} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt8} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt9} alt="ntf-border" />
                                </div>
                            </div>

                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt2} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt16} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt17} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt18} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt19} alt="ntf-border" />
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="nft-border">
                                    <img src={NftBorder} alt="ntf-border" />
                                    <img
                                        src={HoverNftBorder}
                                        alt="ntf-border"
                                        className="HoverNftBorder"
                                    />
                                    <img src={NftArt20} alt="ntf-border" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="icc-coin">
                    <h2 className="section-heading">What's ICC Coin?</h2>
                    <p className="subheading-text-2">
                        ICC Coin is our main ingame currency which can be achieved by
                        purchase in open market or by playing game modes and participating
                        in many other activities.
                    </p>
                    <div className="paly-to-earn">
                        <div className="row justify-content-center align-items-center mx-0">
                            <div className=" col-8 text-center col-lg-4 mt-4 mt-lg-0">
                                <button className="play-to-earn-btn">
                                    <img
                                        src={PlayToEarn}
                                        alt="playtoearn"
                                        className="d-none d-lg-inline-block"
                                    />
                                    <img src={MobileP2E} alt="playtoearn" className="d-lg-none" />
                                    <img
                                        src={PlayToEarnText}
                                        className="ms-xl-3 ms-2 P2E-text"
                                        alt="playtoearn"
                                    />
                                </button>
                            </div>
                            <div className="col-lg-5 text-center">
                                <div className="coin-image">
                                    <div className="coin">
                                        <img src={CoinImg} alt="playtoearn" />
                                    </div>
                                </div>
                                <div className="playnow-btn d-none d-lg-inline-block m-0">
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={
                                            "https://pancakeswap.finance/swap?outputCurrency=0x8D672014Fb107cB409dCcd9042DdA3b97313F4C3"
                                        }
                                    >
                                        <span>Buy Icc Now</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 exchange-btn-main">
                                <div className="exchange-btn">
                                    <button className="play-to-earn-btn">
                                        <img src={Exchange} alt="playtoearn" />
                                        <img src={ExchangeText} className="ms-3" alt="playtoearn" />
                                    </button>
                                </div>
                                <div className="exchange-btn">
                                    <button className="play-to-earn-btn exchange-btn">
                                        <img src={Trade} className="trade-img" alt="playtoearn" />
                                        <img src={TradeText} className="ms-3" alt="playtoearn" />
                                    </button>
                                </div>
                                <div className="exchange-btn">
                                    <button className="play-to-earn-btn exchange-btn">
                                        <img src={Gift} className="gift-img" alt="playtoearn" />
                                        <img src={GiftText} className="ms-3" alt="playtoearn" />
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-12 d-lg-none mt-4 text-center">
                                <div className="playnow-btn m-0">
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={
                                            "https://pancakeswap.finance/swap?outputCurrency=0x8D672014Fb107cB409dCcd9042DdA3b97313F4C3"
                                        }
                                    >
                                        <span>Buy Icc Now</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="core-team">
                    <h2 className="section-heading">CORE TEAM</h2>
                    <p className="subheading-text">
                        The team always plays an important role in the success of a project.
                        As a team, we have been working with each other for long time, with
                        everyone being highly experienced in their own expertise.
                    </p>
                    <p className="subheading-text"></p>
                    <div className="row mt-5 g-0 d-none d-lg-flex">
                        <div className="col-lg-4 col-xl-2 text-center">
                            <div className="core-team-border">
                                <img src={CoreTeam1} alt="core-team" />
                                <img
                                    src={HoverCoreTeam1}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>NIGHTWALKER</h6>
                                <span>FOUNDER</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-2 text-center">
                            <div className="core-team-border">
                                <img src={CoreTeam2} alt="core-team" />
                                <img
                                    src={HoverCoreTeam2}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>KEDICAL</h6>
                                <span>Project Manager & Marketing</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-2 my-3 my-lg-0 text-center">
                            <div className="core-team-border">
                                <img src={CoreTeam3} alt="core-team" />
                                <img
                                    src={HoverCoreTeam3}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                             	<h6>VOLODYMYR</h6>
                                <span>GAME DEVELOPER</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-2 my-3 my-lg-0 mt-lg-3 mt-xl-0 text-center">
                            <div className="core-team-border">
                                <img src={CoreTeam4} alt="core-team" />
                                <img
                                    src={HoverCoreTeam4}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>ANH TU TRAN</h6>
                                <span>Art Director</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-2 mt-lg-3 mt-xl-0 text-center">
                            <div className="core-team-border">
                                <img src={CoreTeam5} alt="core-team" />
                                <img
                                    src={HoverCoreTeam5}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>Elias</h6>
                                <span>Blockchain Developer</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-2 mt-lg-3 mt-xl-0 text-center">
                            <div className="core-team-border">
                                <img src={CoreTeam6} alt="core-team" />
                                <img
                                    src={HoverCoreTeam6}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>DSAHA1656</h6>
                                <span>SYSTEM DESIGNER</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 g-0 d-lg-none">
                        <div className="col-6 text-center">
                            <div className="core-team-border">
                                <img src={MCoreTeam1} alt="core-team" />
                                <img
                                    src={HoverMCoreTeam1}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>NIGHTWALKER</h6>
                                <span>FOUNDER</span>
                            </div>
                        </div>
                        <div className="col-6 text-center">
                            <div className="core-team-border">
                                <img src={MCoreTeam2} alt="core-team" />
                                <img
                                    src={HoverMCoreTeam2}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>KEDICAL</h6>
                                <span>Project Manager & Marketing</span>
                            </div>
                        </div>
                        <div className="col-6 my-3 text-center">
                            <div className="core-team-border">
                                <img src={MCoreTeam3} alt="core-team" />
                                <img
                                    src={HoverMCoreTeam3}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>VOLODYMYR</h6>
                                <span>GAME DEVELOPER</span>
                            </div>
                        </div>
                        <div className="col-6 my-3 text-center">
                            <div className="core-team-border">
                                <img src={MCoreTeam4} alt="core-team" />
                                <img
                                    src={HoverMCoreTeam4}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>ANH TU TRAN</h6>
                                <span>Art Director</span>
                            </div>
                        </div>
                        <div className="col-6 text-center">
                            <div className="core-team-border">
                                <img src={MCoreTeam5} alt="core-team" />
                                <img
                                    src={HoverMCoreTeam5}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>Elias</h6>
                                <span>Blockchain Developer</span>
                            </div>
                        </div>
                        <div className="col-6 text-center">
                            <div className="core-team-border">
                                <img src={MCoreTeam6} alt="core-team" />
                                <img
                                    src={HoverMCoreTeam6}
                                    alt="core-team"
                                    className="HoverCoreImage"
                                />
                            </div>
                            <div className="core-team-details">
                                <h6>DSAHA1656</h6>
                                <span>SYSTEM DESIGNER</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="roadmap-coin-main">
                    <h2 className="section-heading">ROADMAP</h2>
                    <div className="roadmap-coin">
                        <img src={RoadMapCoin} alt="RoadMapCoin" />
                    </div>
                    <div className="phase-main">
                        <div className="row phase-details">
                            <div className="col-xl order-5 order-xl-1">
                                <div className="phase-1">
                                    <span>PHASE 1</span>
                                    <div className="d-flex align-items-xl-center">
                                        <div className="phase-images">
                                            <img
                                                src={PhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseImage"
                                            />
                                            <img
                                                src={HoverPhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseHoverImage"
                                            />
                                        </div>
                                        <p>Token Launch NFT Launch Game launch</p>
                                        <div className="phase-details-box">
                                            <h5>PHASE 1</h5>
                                            <div className="d-flex justify-content-between">
                                                <h6>Token Launch GAME LAUNCH</h6>
                                                <h6 className="ms-2">NFT LAUNCH</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl order-4 order-xl-2">
                                <div className="phase-2">
                                    <span>PHASE 2</span>
                                    <div className="d-flex align-items-xl-center">
                                        <div className="phase-images">
                                            <img
                                                src={PhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseImage"
                                            />
                                            <img
                                                src={HoverPhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseHoverImage"
                                            />
                                        </div>
                                        <p>Trophy Road RACE PASS GUILD SYSTEM FRIEND SYSTEM</p>
                                        <div className="phase-details-box">
                                            <h5>PHASE 2</h5>
                                            <div className="d-flex">
                                                <h6>TROPHY ROAD RACE PASS</h6>
                                                <h6 className="ms-2">GUILD SYSTEM FRIEND SYSTEM</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl order-3 order-xl-3">
                                <div className="phase-3">
                                    <span>PHASE 3</span>
                                    <div className="d-flex align-items-xl-center">
                                        <div className="phase-images">
                                            <img
                                                src={PhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseImage"
                                            />
                                            <img
                                                src={HoverPhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseHoverImage"
                                            />
                                        </div>
                                        <p>STAKING NFTs LEADERBOARD Staking ICC INGAME SHOP</p>
                                        <div className="phase-details-box">
                                            <h5>PHASE 3</h5>
                                            <div className="d-flex">
                                                <h6>STAKING NFT LEADERBOARD</h6>
                                                <h6 className="ms-2">STAKING ICC INGAME SHOP</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl order-2 order-xl-4">
                                <div className="phase-4">
                                    <span>PHASE 4</span>
                                    <div className="d-flex align-xl-items-center">
                                        <div className="phase-images">
                                            <img
                                                src={PhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseImage"
                                            />
                                            <img
                                                src={HoverPhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseHoverImage"
                                            />
                                        </div>
                                        <p>Community Events Tournaments ICC E-Sport</p>
                                        <div className="phase-details-box">
                                            <h5>PHASE 4</h5>
                                            <div className="d-flex">
                                                <h6>Community Events</h6>
                                                <h6 className="ms-2">TOURNAMENTS ICC ESPORT</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl order-1 order-xl-5">
                                <div className="phase-5">
                                    <img src={MoonManImg} alt="MoonManImg" className="moon-man" />
                                    <span className="to-the-moon">TO THE MOON</span>
                                    <div className="d-flex align-items-xl-center">
                                        <span className="phase-arrow"></span>
                                        <div className="phase-images">
                                            <img
                                                src={PhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseImage"
                                            />
                                            <img
                                                src={HoverPhaseImage}
                                                alt="PhaseImage"
                                                className="PhaseHoverImage"
                                            />
                                        </div>
                                        <div className="phase-details-box">
                                            <h5>PHASE 5</h5>
                                            <div className="d-flex">
                                                <h6>TO THE MOON...</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <div className="row mx-0">
                        <div className="col-lg-4 col-xl-auto">
                            <img src={FooterLogo} alt="FooterLogo" className="footer-logo" />
                            <div class="d-lg-none">
                                <p className="about-text text-center text-lg-start mt-3 mt-lg-4">
                                    2023@ INTERGALACTIC COCKROACH . ALL RIGHTS RESERVED.
                                </p>
                                <div className="text-center mt-3 mt-lg-0">
                                    <div className="footer-btn playnow-btn">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://play.icc.vision/"}
                                            className="w-100"
                                        >
                                            <span>Play Now</span>
                                        </a>
                                    </div>
                                    <div className="last-btn text-center my-3 my-lg-0 mt-lg-4">
                                        <div className="playnow-btn">
                                            <a
                                              rel="noreferrer"
                                              target="_blank"
                                              href={
                                                  "https://pancakeswap.finance/swap?outputCurrency=0x8D672014Fb107cB409dCcd9042DdA3b97313F4C3"
                                            }>
                                                <span>BUY ICC NOW</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="last-btn text-center">
                                        <div className="playnow-btn">
                                            <a
                                              rel="noreferrer"
                                              target="_blank"
                                              href={"https://mint.icc.vision/"}
                                            >
                                                <span>MINT NOW</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-xl px-0 mt-lg-0">
                            <div className="footer-menu ul-border">
                                <ul className="d-lg-none">
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            href={"https://icc.vision/"}
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://mint.icc.vision/"}
                                        >
                                            NFT
                                        </a>
                                    </li>
                                    <li className="w-100 white-footer-menu text-center">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={
                                                "https://intergalactic-cockroach.gitbook.io/white-paper/"
                                            }
                                        >
                                            WHITEPAPER
                                        </a>
                                    </li>
                                    <li className="my-xxl-3">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/swap"}
                                        >
                                            TRADE
                                        </a>
                                    </li>
                                    <li className="my-xxl-3">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/farms"}
                                        >
                                            EARN
                                        </a>
                                    </li>
                                    <li className="my-xxl-3">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/swap"}
                                        >
                                            EXCHANGE
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/farms"}
                                        >
                                            FARMS
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/liquidity"}
                                        >
                                            LIQUIDITY
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/pools"}
                                        >
                                            POOLS
                                        </a>
                                    </li>
                                    <li className="w-100 white-footer-menu text-center d-xl-none">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://discord.gg/bJrWycyxpa"}
                                        >
                                            Contact us
                                        </a>
                                    </li>
                                </ul>
                                <ul className="d-none d-lg-flex">
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            href={"https://icc.vision/"}
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/farms"}
                                        >
                                            EARN
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/swap"}
                                        >
                                            TRADE
                                        </a>
                                    </li>
                                    <li className="my-xxl-3">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={
                                                "https://intergalactic-cockroach.gitbook.io/white-paper/"
                                            }
                                        >
                                            WHITEPAPER
                                        </a>
                                    </li>
                                    <li className="my-xxl-3">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/farms"}
                                        >
                                            FARMS
                                        </a>
                                    </li>
                                    <li className="my-xxl-3">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/swap"}
                                        >
                                            EXCHANGE
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://mint.icc.vision/"}
                                        >
                                            NFT
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/pools"}
                                        >
                                            POOLS
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://explore.icc.vision/liquidity"}
                                        >
                                            LIQUIDITY
                                        </a>
                                    </li>
                                    <li className="d-xl-none">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://discord.gg/bJrWycyxpa"}
                                        >
                                            Contact us
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-3 text-center mt-3">
                            <div className="coin-price d-lg-none">
                                <span>${price.toFixed(6)}</span>
                                <img src={FooterCoin} alt="" className="FooterCoin" />
                            </div>
                            <div className="community footer-community">
                                <h6 className="m-0">JOIN OUR COMMUNITY</h6>
                                <div>
                                    <a
                                        href="https://opensea.io/collection/intergalactic-cockroach"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={FooterOpenSeaIcon}
                                            alt=""
                                            className="social-icon"
                                        />
                                    </a>
                                    <a
                                        href="https://discord.gg/bJrWycyxpa"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={FooterDiscordIcon}
                                            alt=""
                                            className="social-icon"
                                        />
                                    </a>
                                    <a
                                        href="https://twitter.com/icc_vision"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={FooterTwitterIcon}
                                            alt=""
                                            className="social-icon"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="footer-menu mt-3 d-none d-xl-inline-block">
                                <ul className="justify-content-center">
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://discord.gg/bJrWycyxpa"}
                                        >
                                            Contact us
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end mx-0 mt-5">
                        <div className="col-xl-5 col-xxl-6 text-lg-center text-xl-start">
                            <div className="footer-btn playnow-btn d-none d-lg-inline-block">
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={"https://play.icc.vision/"}
                                    className="w-100 text-center"
                                >
                                    <span>Play Now</span>
                                </a>
                            </div>
                            <p className="about-text text-start mt-4 d-none d-lg-inline-block">
                                2023@ INTERGALACTIC COCKROACH . ALL RIGHTS RESERVED.
                            </p>
                        </div>
                        <div className="col-xl-7 col-xxl-6 d-flex align-items-end mt-lg-5 mt-xl-0 justify-content-end footer-last">
                            <a
                                rel="noreferrer"
                                // target="_blank"
                                href={
                                    // "https://api.pancakeswap.info/api/v2/tokens/0x8d672014fb107cb409dccd9042dda3b97313f4c3"
                                    // "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=intergalactic-cockroach&order=market_cap_desc&per_page=100&page=1&sparkline=false"
                                    "#"
                                }
                                className="coin-price d-none d-lg-inline-block"
                            >
                                <span>${price.toFixed(6)}</span>
                                <img src={FooterCoin} alt="" className="FooterCoin" />
                            </a>
                            <div className="d-none d-lg-inline two-buttons">
                                <div className="last-btn">
                                    <div className="playnow-btn">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={"https://mint.icc.vision/"}
                                        >
                                            <span>MINT NOW</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="last-btn mt-xl-4">
                                    <div className="playnow-btn">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={
                                                "https://pancakeswap.finance/swap?outputCurrency=0x8D672014Fb107cB409dCcd9042DdA3b97313F4C3"
                                            }
                                        >
                                            <span>BUY ICC</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Landing;
